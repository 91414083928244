import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'
import ContactForm from 'components/contact-form'
import RootCanalGif from '../../../content/images/root-canal-new-img.gif'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const EndodonticRetreatmentPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Endodontic Retreatment Indianapolis IN</title>
        <meta name="description" content="Endodontist Dr. Broady. Endodontic retreatment is sometimes needed after initial treamtment. Call our Indianapolis IN office at 317-846-4980 for more information" />
        <link rel="canonical" href="https://meridianendoindy.com/procedures/endodontic-retreatment/" />
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Endodontic Retreatment Indianapolis IN" />
        <meta name="og:description" content="Endodontist Dr. Broady. Endodontic retreatment is sometimes needed after initial treamtment. Call our Indianapolis IN office at 317-846-4980 for more information" />
        <meta property="og:url" content="https://meridianendoindy.com/procedures/endodontic-retreatment/" />
        <meta property="og:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Endodontic Retreatment Indianapolis IN" />
        <meta name="twitter:description" content="Endodontist Dr. Broady. Endodontic retreatment is sometimes needed after initial treamtment. Call our Indianapolis IN office at 317-846-4980 for more information" />
        <meta name="twitter:url" content="https://meridianendoindy.com/procedures/endodontic-retreatment/" />
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="endodontic-retreatment-hero-section-bg hero-bg"
        fluid={hero_background}
        backgroundColor={`#ffffff`}
        alt="Endodontics pc"
      >
      <div id="endodontic-retreatment-hero-section">
        <Container>
          <div className="col-lg-6 hero-left">
            <h1 className="hero-heading primary-text">
              Endodontic Retreatment
            </h1>
            <p className="hero-text">
              With the appropriate care, your teeth that have had endodontic treatment will last as long as other natural teeth.
            </p>
            <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
          </div>
          <div className="col-lg-6">
            <Img fluid={hero_img} className="hero-img"/>
          </div>
        </Container>
      </div>
    </BackgroundImage>
    <section id="faded-bg"></section>
    <section id="endodontic-retreatment-section-1">
      <Container>
          <div className="col-lg-12 endodontic-retreatment-container">
            <div className="row">
              <div className="col-lg-8">
                <h4>Endodontic Retreatment</h4>
                <p>
                  With the right care, a tooth that's gone through endodontic treatment will last as long as your other natural teeth. Unfortunately, sometimes treatment doesn't work, or the tooth fails to heal. In addition, the pain can return in a few months or even years after the initial root canal. The good news is that this doesn't mean you are out of options to save that tooth. Our endodontists can retreat your tooth.
                </p>
              </div>
              <div className="col-lg-4">
              <img src={RootCanalGif} className="root-canal-gif"/>
                {/*<iframe className="section1-video" src="https://fast.wistia.net/embed/iframe/mz1l9373vc"></iframe>
                <p className="text-center click-here"><a href="https://fast.wistia.net/embed/iframe/mz1l9373vc" target="_blank">Click here for high-res version</a></p> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <p className="row2-p">
                  <strong>Endodontic retreatment might be necessary when the tooth fails to heal.<br /> Reasons this situation can occur include:</strong>
                </p>
                <ul>
                  <li>
                    Narrow or curved canals weren't part of the initial treatment
                  </li>
                  <li>
                    Complexities in the canals were missed in the first procedure
                  </li>
                  <li>
                    The restoration process wasn't completed promptly
                  </li>
                  <li>
                    Problems with the crown placement introduced saliva inside the tooth, causing contamination
                  </li>
                </ul>
                <p className="row3-p">
                  <strong>Additionally, new issues can arise, leading to pain and swelling. These issues also require retreatment. Possible complications include:</strong>
                </p>
                <ul>
                  <li>
                    New decay exposing the root canal, leading to infection
                  </li>
                  <li>
                    Problems with the crown or restoration process, such as cracks or poor fit, causing a new infection
                  </li>
                </ul>
                <p>
                  Nonetheless, Meridian Endodontics is available to assess the situation and complete the retreatment procedure. The doctor will reopen the tooth to access the root canal filling material and remove it. Next, they'll clean out the canal and reassess the tooth. Lastly, the endodontist refills and seals the canal. Again, a temporary filling is placed to protect the area until a crown is placed.
                </p>
                <p>
                  <strong>The patient needs to get to their dentist for the restorative process. The crown is the last step to preventing a complete loss of the tooth.</strong>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>

    <section id="endodontic-retreatment-section-2">
      <Container>
        <div className="col-lg-12 section2-video">
          <iframe className="youtube-video" src="https://www.youtube.com/embed/xlYYc7_BFTI"></iframe>
        </div>
      </Container>
    </section>

    <section id="endodontic-retreatment-section-3">
      <ContactForm/>
    </section>
    </Layout>
  )
}

export default EndodonticRetreatmentPage

export const query = graphql`
  query EndodonticRetreatmentPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "endodontic-retreatment-hero" }) {
      childImageSharp {
        fluid(maxWidth: 520, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
